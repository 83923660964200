<template>
  <div :class="[classes.wrapper, error ? classes.wrapperInvalid : '']">
    <input
      :id="id"
      v-model="inputValue"
      v-maska
      :class="[
        input({ intent, type }),
        error && !readonly ? classes.invalidInput : '',
        !!readonly ? classes.readonlyInput : '',
        className,
      ]"
      :type="inputType"
      :minlength="minlength"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :inputmode="inputMode"
      :required="required"
      :readonly="readonly"
      :data-maska="mask"
      :autocomplete="autocomplete"
      spellcheck="false"
      @input="
        emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
    >
    <label v-if="id" :class="classes.label" :for="id">
      {{ placeholder }}
    </label>
    <div v-show="error && !readonly" :class="classes.invalidText">
      {{ error }}
    </div>
    <Icon
      v-if="error && !readonly"
      :class="classes.icon"
      name="common:input-error"
      size="20"
    />
  </div>
</template>

<script setup lang="ts">
import { cva, cx, type VariantProps } from 'cva'
import { computed } from 'vue'
import { vMaska } from 'maska/vue'

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  intent: 'primary',
  type: 'input',
  id: '',
  minlength: 0,
  maxlength: undefined,
  placeholder: '',
  error: '',
  className: '',
  inputType: 'text',
  inputMode: 'text',
  required: false,
  mask: '',
  readonly: false,
  autocomplete: '',
})

const emit = defineEmits(['update:modelValue'])

const classes = {
  wrapper: 'relative',
  wrapperInvalid: cx(['group', 'is-invalid']),
  primary: cx([
    'border',
    'border-solid',
    'border-border-primary',
    'focus:border-border-active',
    'bg-bg-main-primary',
    'rounded-lg',
    'font-normal',
    'text-[16px]/[130%]',
    'text-primary',
    'duration-300',
    'outline-0',
    'appearance-none',
  ]),
  input: cx([
    'w-full',
    'min-h-[56px]',
    'py-[18px]',
    'px-[15px]',
    'placeholder:text-disable',
    'placeholder:opacity-0',
    'placeholder:invisible',
    'peer',
    'focus:pt-[23px]',
    'focus:py-[15px]',
    'focus:pb-[13px]',
    '[&:not(:placeholder-shown)]:pt-[23px]',
    '[&:not(:placeholder-shown)]:py-[15px]',
    '[&:not(:placeholder-shown)]:pb-[13px]',
  ]),
  searchInput: cx(['py-2', 'px-[13px]', 'border', 'border-border-active']),
  invalidInput: cx(['!border-border-error-disable', 'bg-bg-main-secondary']),
  readonlyInput: cx(['!border-border-primary', 'cursor-not-allowed']),
  invalidText: cx(['text-[11px]/[120%]', 'text-error', 'mt-2']),
  label: cx([
    'absolute',
    'text-ghost',
    'left-[18px]',
    'top-[50%]',
    '-translate-y-2/4',
    'transition-all',
    'duration-300',
    'cursor-text',
    'pointer-events-none',
    'peer-focus:top-[20px]',
    'peer-focus:z-10',
    'peer-focus:text-[11px]',
    'peer-focus:text-ghost',
    'peer-focus:transition-all',
    'peer-focus:duration-200',
    'peer-[:not(:placeholder-shown)]:top-[20px]',
    'peer-[:not(:placeholder-shown)]:z-10',
    'peer-[:not(:placeholder-shown)]:text-[11px]',
    'peer-[:not(:placeholder-shown)]:text-ghost',
    'peer-[:not(:placeholder-shown)]:transition-all',
    'peer-[:not(:placeholder-shown)]:duration-200',
    'group-[.is-invalid]:top-[38%]',
  ]),
  icon: cx(['absolute', 'right-[16px]', 'top-[30px]', '-translate-y-1/2']),
}

const input = cva([], {
  variants: {
    intent: {
      primary: classes.primary,
    },
    type: {
      input: classes.input,
      search: classes.searchInput,
    },
  },
})

type InputTextProps = VariantProps<typeof input>

interface Props {
  modelValue?: string
  intent: InputTextProps['intent']
  type: InputTextProps['type']
  id?: string
  minlength?: number
  maxlength?: number
  placeholder?: string
  error?: string
  className?: string
  inputType?: 'text' | 'email'
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search' // HTMLAttributes.inputMode
  required?: boolean
  mask?: string
  readonly?: boolean
  autocomplete?: string
}

const inputValue = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  },
})
</script>